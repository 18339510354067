import "./roster-note";
import "./popover";
import { until } from "lit/directives/until.js";
import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import {
    Venue,
    Employee,
    TimeEntry,
    TimeEntryType,
    Position,
    BreakMode,
    RosterNote,
    EmployeeStatus,
    employeeStatusColor,
    employeeStatusLabel,
} from "@pentacode/core/src/model";
import { version } from "@pentacode/core/src/version";
import { minute, hour } from "@pentacode/core/src/hours";
import { dateAdd, formatDate, toDateString, toTimeString, getRange, throttle } from "@pentacode/core/src/util";
import {
    GetRosterNotesParams,
    TimeTrackerLog,
    TimeTrackerLogEvent,
    TimeTrackerLogEventType,
} from "@pentacode/core/src/api";
import { clone } from "@pentacode/core/src/encoding";
import { app, router } from "../init";
import { config, shared, mixins, colors } from "../styles";
import { StateMixin } from "../mixins/state";
import { ServiceWorker } from "../mixins/service-worker";
import { ErrorHandling } from "../mixins/error-handling";
import Logo from "../img/logo.svg";
import { confirm, alert } from "./alert-dialog";
import { Dialog } from "./dialog";
import "./avatar";
import "./spinner";
import { Balance } from "./balance";
import "./scroller";
import { Checkbox } from "./checkbox";
import { SelectCompanyDialog } from "./select-company-dialog";
import { singleton } from "../lib/singleton";
import "./qr-code";
import { DateString, Hours, add } from "@pentacode/openapi/src/units";
import { matchesFilters } from "@pentacode/core/src/filters";
import { triggerReflow } from "../lib/util";

@customElement("ptc-time-tracker")
export class TimeTracker extends ServiceWorker(ErrorHandling(StateMixin(LitElement))) {
    @property({ type: Boolean, reflect: true, attribute: "singleton-container" })
    readonly singletonContainer = true;

    @state()
    private _venue?: Venue;

    @state()
    private _filterDepartments?: number[];

    @state()
    private _pin: string = "";

    @state()
    private _selectedEmployee: Employee | null = null;

    @state()
    private _selectedTimeEntry: TimeEntry | null = null;

    @state()
    private _loading: boolean = false;

    @state()
    private _synching: boolean = false;

    @state()
    private _loginError: string = "";

    @state()
    private _pinError: string = "";

    @state()
    private _lastSync: Date = new Date(0);

    @state()
    private _timeEntries: TimeEntry[] = [];

    @state()
    private _rosterNotes: RosterNote[] = [];

    @state()
    private _date: DateString;

    @state()
    private _publishedRosterUrl = "";

    @query("#venueSelector")
    private _venueSelector: HTMLSelectElement;

    @query("#email")
    private _emailInput: HTMLInputElement;

    @query("#password")
    private _passwordInput: HTMLInputElement;

    @query("#selectPositionDialog")
    private _selectPositionDialog: Dialog<void, void>;

    @query(".published-roster-dialog")
    private _publishedRosterDialog: Dialog<void, void>;

    @query("#filterDepartmentsDialog")
    private _filterDepartmentsDialog: Dialog<void, void>;

    @query("#qrDialog")
    private _qrDialog: Dialog<void, void>;

    @query("#filterDepartmentsForm")
    private _filterDepartmentsForm: HTMLFormElement;

    @singleton("ptc-select-company-dialog")
    private _selectCompanyDialog: SelectCompanyDialog;

    private _pollTimeout: number;

    private _eventLog = new TimeTrackerLog();

    private get _longTimeWithoutSync() {
        return Date.now() - this._lastSync.getTime() > 1 * hour;
    }

    private get _venues() {
        return app.accessibleVenues;
    }

    private get _rosterAvailable() {
        return this._venue && app.hasPermission(`manage.roster`);
    }

    private get _availableShifts() {
        if (!app.company) {
            return [];
        }

        return this._timeEntries.filter((timeEntry) => {
            const { checkinWindow, checkoutWindow, trackingEnabled } = app.getTimeSettings({ timeEntry });
            const earliestCheckin = new Date(Date.now() + checkinWindow * hour);
            const latestCheckout = new Date(Date.now() - checkoutWindow * hour);
            return (
                !!timeEntry.position &&
                (!this._filterDepartments || this._filterDepartments.includes(timeEntry.position.departmentId)) &&
                trackingEnabled &&
                timeEntry.start < earliestCheckin &&
                (!timeEntry.planned ||
                    timeEntry.start > latestCheckout ||
                    timeEntry.planned[1] > new Date(Date.now() - hour))
            );
        });
    }

    private get _staffLoginURL() {
        return (
            app.company &&
            app.company.tempAuthTokens &&
            `${process.env.PTC_STAFF_URL}/tracking?c=${app.company.id}&tat=${app.company.tempAuthTokens[0]}`
        );
    }

    constructor() {
        super();
        this._load();
    }

    async _load() {
        router.addEventListener("route-changed", () => this.requestUpdate());
        await app.loaded;

        try {
            const log = await app.storage.get(TimeTrackerLog, "time_log");
            this._eventLog.add(...log.events);
        } catch (e) {}
        this._log("start");

        window.addEventListener("error", (e: ErrorEvent) =>
            this._log("error", { error: e.error.stack || e.error.toString() })
        );
        window.addEventListener("unhandledrejection", (e: PromiseRejectionEvent) =>
            this._log("error", { error: e.reason.stack || e.reason.toString() })
        );

        if (app.account) {
            this._poll(true);
        }

        setInterval(() => this.requestUpdate(), 1000 * 60);

        document.addEventListener("visibilitychange", () => {
            if (!document.hidden && this._longTimeWithoutSync) {
                this._poll();
            }
        });

        document.addEventListener("click", () => {
            if (this._longTimeWithoutSync) {
                this._poll();
            }
        });

        const loadingScreen = document.querySelector(".loading-screen") as HTMLElement;
        loadingScreen.style.display = "none";
    }

    private _saveLog = throttle(async () => {
        try {
            const log = clone(this._eventLog);
            log.events = log.events.slice(0, 50);
            await app.storage.set("time_log", log);
        } catch (e) {
            await app.storage.delete(TimeTrackerLog, "time_log");
        }
    }, 5000);

    private _log(type: TimeTrackerLogEventType, evt: Partial<TimeTrackerLogEvent> = {}) {
        this._eventLog.add({
            type,
            time: new Date(),
            lastSync: this._lastSync,
            offline: this.offline,
            venue: this._venue && this._venue.id,
            version,
            employee: evt.employee || (evt.entry && evt.entry.employeeId) || undefined,
            timeSettings: evt.timeSettings || (evt.entry && app.getTimeSettings({ timeEntry: evt.entry })),
            departments: this._filterDepartments,
            ...evt,
        });
        this._saveLog();
    }

    private async _enter(e: MouseEvent) {
        if (!app.company) {
            return;
        }

        const button = e.target as HTMLButtonElement;
        const dig = button.dataset.digit;

        this._pinError = "";

        if (this._pin.length >= 4) {
            this._pin = "";
        }

        this._pin += dig;

        await this.updateComplete;
        if (this._pin.length === 4) {
            if (this._pin === "*00#") {
                await this._logout();
                this._pin = "";
                return;
            } else if (this._pin === "*99#") {
                if (
                    await confirm(
                        "Möchten Sie das Ereignisprotokoll and das Pentacode Supportteam schicken?",
                        "Versenden",
                        "Abbrechen",
                        { title: "Ereignisprotokoll Versenden" }
                    )
                ) {
                    this._loading = true;
                    try {
                        await app.api.sendTimeTrackerLog(this._eventLog);
                    } catch (e) {
                        alert(e.message, { type: "warning" });
                    }
                    this._loading = false;
                    alert("Ereignisprotokoll erfolgreich versandt.", { type: "success" });
                }
                this._pin = "";
                return;
            }

            const today = toDateString(new Date());
            const employee = app.company.employees.find((emp) => {
                const matchesPin = emp.timePin === this._pin;
                if (!matchesPin) {
                    return false;
                }
                const contract = emp.getContractForDate(today);
                return !!contract && !contract.blocked;
            });

            if (
                employee &&
                employee.positions.some((position) => {
                    const { venue } = app.getDepartment(position.departmentId);
                    return !!venue && venue.id === this._venue!.id;
                })
            ) {
                this._selectEmployee(employee);
            } else {
                this._pinError = "PIN nicht erkannt";
                this._rumble();
            }
        }
    }

    private _back() {
        this._selectedEmployee = this._selectedTimeEntry = null;
        this._pin = "";
    }

    private async _synchronize(manual = false) {
        if (this._loading) {
            return;
        }

        if (manual) {
            this._loading = true;
        }
        this._synching = true;

        try {
            await app.fetchAccount();

            if (!app.company) {
                const companyId = (await this._selectCompanyDialog.show())!;
                await app.selectCompany(companyId);
            }

            const today = (this._date = toDateString(new Date()));
            this._venue = this._venues.find((v) => v.id.toString() === router.params.venue) || this._venues[0];
            if (this._venue && router.params.venue !== this._venue.id.toString()) {
                router.params = { ...router.params, venue: this._venue.id.toString() };
            }
            this._filterDepartments =
                (router.params.departments && router.params.departments.split(",").map((id) => parseInt(id))) ||
                undefined;

            const from = dateAdd(today, { days: -1 });
            const to = dateAdd(today, { days: 2 });
            const [timeEntries, rosterNotes] = await Promise.all([
                app.getTimeEntries({
                    from,
                    to,
                    venue: this._venue?.id,
                    type: [TimeEntryType.Work],
                }),
                app.api.getRosterNotes(
                    new GetRosterNotesParams({
                        from,
                        to,
                        venue: this._venue?.id,
                    })
                ),
            ]);
            this._timeEntries = timeEntries;
            this._rosterNotes = rosterNotes;
            await app.syncTimeEntries();

            this._lastSync = new Date();

            if (manual) {
                this._log("sync");
            }
        } catch (e) {
            console.error("Sync failed. Error: ", e);
            this._log("error", { error: e.toString() });
            if (manual) {
                alert(e.message, { type: "warning" });
            }
        }

        if (manual) {
            this._loading = false;
        }
        this._synching = false;

        if (manual) {
            this.pollForUpdates();
        }
    }

    private _poll = throttle(async (manual = false, interval = minute * 5) => {
        clearTimeout(this._pollTimeout);

        if (app.account) {
            await this._synchronize(manual);
        }

        this._pollTimeout = window.setTimeout(() => this._poll(false, interval), interval);
    }, 5000);

    private _isCheckedIn(a?: TimeEntry | null) {
        a = a || this._selectedTimeEntry;
        return !!a && !!a.startFinal;
    }

    private _isCheckedOut(a?: TimeEntry | null) {
        a = a || this._selectedTimeEntry;
        return !!a && !!a.endFinal;
    }

    private _isLate(a?: TimeEntry | null) {
        a = a || this._selectedTimeEntry;
        return !!a && !a.startLogged && a.start < new Date();
    }

    private _isOnBreak(a?: TimeEntry | null) {
        a = a || this._selectedTimeEntry;
        return !!a && !!a.startBreak;
    }

    private async _checkIn(entry?: TimeEntry | null) {
        entry = entry || this._selectedTimeEntry;

        if (!entry) {
            return;
        }

        const logged = new Date();
        const { startPlanned } = entry;

        const { checkinRounding, applyEarlyCheckin } = app.getTimeSettings({ timeEntry: entry });

        // If logged time is earlier than planned and applyEarlyCheckin setting
        // is not set, use planned time instead
        let final =
            startPlanned && logged < startPlanned && !applyEarlyCheckin ? new Date(startPlanned) : new Date(logged);

        // Apply rounding
        if (checkinRounding) {
            const minutes = final.getMinutes();
            const roundedMinutes = minutes - (minutes % checkinRounding) + Math.max(0, checkinRounding);
            final.setMinutes(roundedMinutes);
        }

        // Round to full minute
        final.setSeconds(0);
        final.setMilliseconds(0);

        // Dont round over planned time
        if (
            (startPlanned && final > startPlanned && logged <= startPlanned) ||
            (startPlanned && final < startPlanned && logged >= startPlanned)
        ) {
            final = new Date(startPlanned);
        }

        entry.startLogged = logged;
        entry.startFinal = final;

        if (toDateString(final) !== entry.date) {
            entry.date = toDateString(final);
        }

        app.createOrUpdateTimeEntries(entry);

        this._back();
        this.requestUpdate();

        this._log("checkin", { entry: clone(entry) });
    }

    private async _checkOut() {
        if (!this._selectedTimeEntry) {
            return;
        }

        const entry = this._selectedTimeEntry;
        const logged = new Date();
        const { startFinal, endPlanned } = entry;
        const { applyLateCheckout, checkoutRounding } = app.getTimeSettings({ timeEntry: entry });

        if (!startFinal) {
            throw "Employee needs to check in first!";
        }

        // If logged time is later than planned and applyLateCheckout setting
        // is not set, use planned time instead
        let final = endPlanned && logged > endPlanned && !applyLateCheckout ? new Date(endPlanned) : new Date(logged);

        // Apply rounding
        if (checkoutRounding) {
            const minutes = final.getMinutes();
            const roundedMinutes = minutes - (minutes % checkoutRounding) + Math.max(0, checkoutRounding);
            final.setMinutes(roundedMinutes);
        }

        // Round to full minute
        final.setSeconds(0);
        final.setMilliseconds(0);

        // Dont round over planned time
        if (
            (endPlanned && final > endPlanned && logged <= endPlanned) ||
            (endPlanned && final < endPlanned && logged >= endPlanned)
        ) {
            final = new Date(endPlanned);
        }

        // Make sure end time is not earlier than start time
        if (final < entry.startFinal!) {
            final = entry.startFinal!;
        }

        entry.endLogged = logged;
        entry.endFinal = final;

        app.createOrUpdateTimeEntries(entry, { applyAutoMeals: true, otherEntries: this._timeEntries });

        this.requestUpdate();

        this._log("checkout", { entry: clone(entry) });
    }

    private async _startBreak() {
        const entry = this._selectedTimeEntry;
        if (!entry || this._isOnBreak()) {
            return;
        }
        entry.startBreak = new Date();
        app.createOrUpdateTimeEntries(entry);
        this._back();

        this._log("startbreak", { entry: clone(entry) });
    }

    private async _endBreak() {
        const entry = this._selectedTimeEntry;
        if (!entry || !this._isOnBreak()) {
            return;
        }
        const dur = ((Date.now() - entry.startBreak!.getTime()) / hour) as Hours;
        entry.breakLogged = add(entry.breakLogged || (0 as Hours), dur);
        entry.startBreak = null;
        app.createOrUpdateTimeEntries(entry);
        this._back();

        this._log("endbreak", { entry: clone(entry) });
    }

    private _status(a?: TimeEntry) {
        const s = a || this._selectedTimeEntry;
        return this._isCheckedOut(s)
            ? "complete"
            : this._isOnBreak(s)
              ? "onbreak"
              : this._isCheckedIn(s)
                ? "active"
                : this._isLate(s)
                  ? "late"
                  : "planned";
    }

    private _venueSelected() {
        router.go("", { venue: this._venueSelector.value });
        this.requestUpdate();
        this._poll(true);
    }

    private async _login(e: FocusEvent) {
        e.preventDefault();
        this._loading = true;
        this._loginError = "";

        try {
            await app.login({ email: this._emailInput.value, password: this._passwordInput.value, scope: "time" });
            await this.updateComplete;
            this._emailInput.value = this._passwordInput.value = "";
            this._loading = false;
            this._poll(true);
            this._log("login");
        } catch (e) {
            this._loading = false;
            this._loginError = e.message || "Anmeldung fehlgeschlagen. Bitte versuchen Sie es sp\u00e4ter noch einmal!";
            this._log("error", { error: e.message });
        }
    }

    private async _logout() {
        const confirmed = await confirm("Sind Sie sicher dass Sie sich ausloggen möchten?", "Ausloggen");
        if (confirmed) {
            await app.logout();
            this._log("logout");
            setTimeout(() => window.location.reload(), 500);
        }
    }

    private _clearPin() {
        this._pin = "";
        this._pinError = "";
    }

    private _rumble() {
        const el = this.renderRoot.querySelector(".selection") as HTMLElement;

        el.classList.remove("rumble");
        triggerReflow(el);
        el.classList.add("rumble");
    }

    private async _selectEmployee(employee: Employee) {
        this._selectedEmployee = employee;
        this._selectedTimeEntry =
            this._availableShifts.find(
                (a) => a.employeeId === employee.id && this._isCheckedIn(a) && !this._isCheckedOut(a)
            ) ||
            this._availableShifts.find((a) => a.employeeId === employee.id && !this._isCheckedIn(a)) ||
            null;
        this._log("attend", {
            employee: employee.id,
            entry: clone(this._selectedTimeEntry) || undefined,
            entries: this._timeEntries.filter((e) => e.employeeId === employee.id).map(clone),
            available: this._availableShifts.filter((e) => e.employeeId === employee.id).map(clone),
        });
    }

    private _selectPosition() {
        this._selectPositionDialog.show();
    }

    private async _createTimeEntry(position: Position) {
        this._selectPositionDialog.dismiss();

        if (!this._selectedEmployee) {
            return;
        }

        const date = toDateString(new Date());

        const entry = new TimeEntry({ employeeId: this._selectedEmployee.id, position, date });
        entry.setPublished();
        this._timeEntries.push(entry);
        this._checkIn(entry);
    }

    private async _updateAvatar({ detail: { image } }: CustomEvent) {
        if (!this._selectedEmployee) {
            return;
        }
        await app.updateEmployee(this._selectedEmployee, { avatar: image });
        this._poll(true);
    }

    private async _viewRoster() {
        this._publishedRosterDialog.show();
    }

    private async _loadPublishedRoster() {
        if (!this._rosterAvailable) {
            return;
        }
        const { from, to } = getRange(this._date, "week");
        let url = `/dp/?venue=${this._venue!.id}&from=${from}&to=${to}`;
        if (this._filterDepartments) {
            url += `&d=${this._filterDepartments.join(",")}`;
        }
        this._publishedRosterUrl = url;
    }

    private _submitFilterDepartments(e: Event) {
        e.preventDefault();
        this._filterDepartmentsDialog.dismiss();
        const data = new FormData(this._filterDepartmentsForm);
        const departments = (data.getAll("departments") as string[]).map((id) => parseInt(id));
        const params: any = { venue: this._venueSelector.value };
        if (departments.length !== this._venue!.departments.length) {
            params.departments = departments.join(",");
        }
        router.go("", params);
        this.requestUpdate();
        this._poll(true);
    }

    updated(changes: Map<string, any>) {
        if (changes.has("_date") || changes.has("_venue") || changes.has("_filterDepartments")) {
            this._loadPublishedRoster();
        }
    }

    static styles = [
        config,
        shared,
        Checkbox.styles,
        Balance.styles,
        css`
            :host {
                display: block;
                ${mixins.fullbleed()};
                background: var(--color-bg);
            }

            .wrapper {
                ${mixins.fullbleed()};
                margin: auto;
                transition:
                    transform 150ms,
                    opacity 150ms;
                will-change: transform, opacity;
                transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
                transition-delay: 150ms;
            }

            .wrapper:not([showing]) {
                transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
                transition-delay: 0s;
                opacity: 0;
                pointer-events: none;
            }

            .selection.wrapper {
                ${mixins.fullbleed()};
                max-width: 1000px;
                max-height: 735px;
                margin: auto;
                display: grid;
                grid-template: "planned pinpad checkedin" 100% / 1fr auto 1fr;
                padding: 1em 1em 2em 1em;
                grid-gap: 1em;
            }

            .selection.wrapper:not([showing]) {
                transform: scale(0.95);
            }

            .clock {
                text-align: center;
                grid-column: span 3;
            }

            .clock .time {
                font-size: 400%;
                line-height: 1;
                font-weight: 300;
            }

            .venue {
                border: none;
                font-size: 1.2em;
                margin-bottom: -0.5em;
                background: transparent;
                max-width: 100%;
            }

            .venue[disabled] {
                opacity: 1;
                padding-right: 0.8em;
                background-image: none;
            }

            .footer {
                font-size: var(--font-size-small);
                position: absolute;
                bottom: 5px;
                right: 0;
                left: 0;
            }

            .footer > i {
                margin: 0 0.5em;
            }

            .last-sync.old {
                color: var(--color-negative);
            }

            @keyframes blink {
                70% {
                    opacity: 0;
                }
            }

            .connection-status {
                margin-left: 0.5em;
                animation: blink 4s infinite;
                border-radius: 0.5em;
                padding: 0.1em 0.3em 0.1em 0.1em;
            }

            .connection-status.online {
                color: var(--color-positive);
            }

            .connection-status.offline {
                color: var(--color-negative);
                border: dashed 1px;
            }

            .pinpad-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                grid-area: pinpad;
            }

            .pinpad {
                display: grid;
                grid-template: 10.5em repeat(5, 7.5em) / repeat(3, 8em);
                grid-gap: 0.5em;
            }

            .pinpad > button {
                margin: 0;
                padding: 0;
                border-radius: 1em;
                background: transparent;
                border: solid 1px var(--shade-2);
                position: relative;
                font-weight: 300;
            }

            .pinpad > button::after {
                font-size: 200%;
                content: attr(data-digit);
                width: 1em;
                height: 1em;
                line-height: 1em;
                ${mixins.fullbleed()};
                margin: auto;
            }

            .pinpad > button.special {
                background: transparent;
                border-color: transparent;
            }

            .pin-display {
                grid-column: span 3;
                border: solid 2px var(--shade-2);
                border-radius: 1em;
                position: relative;
                margin-bottom: 5px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }

            .pin-display > .numbers {
                font-size: 3em;
                letter-spacing: 0.1em;
            }

            .pin-display > .clear-pin {
                font-size: 2em;
                position: absolute;
                right: 20px;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            .pin-display .pin-error {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2em;
                line-height: 2em;
                color: var(--color-negative);
                font-weight: 600;
            }

            .qr-container {
                grid-column: span 3;
                position: relative;
                margin-bottom: 5px;
                text-align: center;
                overflow: hidden;
            }

            .section {
                display: flex;
                flex-direction: column;
            }

            .section .header {
                font-size: var(--font-size-large);
                font-variant: small-caps;
                letter-spacing: 0.1em;
                padding: 0 0.5em;
                background: var(--color-bg);
                margin-bottom: -0.5em;
                z-index: 1;
                width: auto;
                align-self: center;
                border-radius: 1em;
            }

            .section .body {
                flex: 1;
                border: solid 1px var(--shade-2);
                border-radius: 1em;
                position: relative;
            }

            .section.planned {
                grid-area: planned;
            }

            .section.checkedin {
                grid-area: checkedin;
            }

            .time-entry {
                margin: 0.7em;
                display: flex;
                align-items: flex-start;
            }

            .time-entry:first-child {
                margin-top: 1em;
            }

            .time-entry .middle {
                width: 0;
                margin: 0 0.7em;
                flex: 1;
            }

            .time-entry .time {
                font-size: 120%;
                font-weight: bold;
                color: var(--color-primary);
            }

            .time-entry .position {
                opacity: 0.8;
            }

            .time-entry .name {
                font-weight: bold;
            }

            .time-entry.late .time {
                color: var(--red);
            }

            .employee-actions {
                background: var(--color-bg);
                z-index: 10;
            }

            .employee-actions:not([showing]) {
                transform: scale(1.05);
            }

            .employee-actions-inner {
                width: 100%;
                box-sizing: border-box;
                max-width: 32em;
                padding: 1em;
            }

            .employee-actions-inner ptc-spinner {
                margin: 1.5em auto;
            }

            .employee-actions ptc-avatar {
                align-self: center;
                font-size: var(--font-size-enormous);
            }

            .employee-actions .employee-name {
                font-size: var(--font-size-big);
                text-align: center;
                margin-top: 0.5em;
            }

            .selected-time-entry {
                padding: 0.8em;
                margin: 1em 0;
            }

            .tiles.shift-actions {
                font-size: var(--font-size-medium);
                margin-top: 0.5em;
            }

            .employee-actions button {
                border-radius: calc(2 * var(--border-radius));
            }

            .selected-time-entry-label {
                text-align: center;
                font-weight: 600;
                font-size: var(--font-size-large);
                margin: 0.5em;
            }

            .selected-time-entry-label.planned,
            .selected-time-entry-label.late {
                color: var(--color-fg);
            }

            .selected-time-entry-label.active {
                color: var(--color-primary);
            }

            .selected-time-entry-label.onbreak {
                color: var(--orange);
            }

            .selected-time-entry-label.complete {
                color: var(--color-positive);
            }

            .not-planned {
                font-size: var(--font-size-large);
                margin: 0.8em 0.5em 1.5em 0.5em;
                text-align: center;
                color: #888;
            }

            .close-button {
                position: absolute;
                right: 0.5em;
                top: 0.5em;
                z-index: 1;
            }

            .login {
                ${mixins.fullbleed()};
                ${mixins.scroll()};
                z-index: 100;
                background: var(--color-bg);
                padding: 2em;
            }

            .login .container {
                min-height: 100%;
                text-align: center;
            }

            .login h1 {
                margin: 0.5em;
            }

            .card {
                background: var(--color-bg);
                border-radius: calc(2 * var(--border-radius));
                box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px -5px;
                position: relative;
                overflow: hidden;
            }

            .login .card {
                width: 300px;
                padding: 1em;
                color: var(--color-fg);
                margin: 2em;
            }

            .login .logo {
                height: 50px;
            }

            .login .greeting {
                margin-top: 1em;
                width: 350px;
                text-align: center;
            }

            .login input {
                text-align: inherit;
            }

            .login .message {
                margin: 0 0 0.8em 0;
            }

            .rumble {
                animation: rumble 300ms;
            }

            .select-position-dialog > .inner {
                text-align: center;
                padding: 10px;
            }

            .select-position-dialog h1,
            .select-position-dialog h2 {
                margin: 0.5em;
            }

            .select-position-dialog button {
                margin-top: 0.5em;
                width: 100%;
            }

            .tiles {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                grid-gap: 0.5em;
                margin-top: 0.5em;
            }

            .tile {
                text-align: center;
                padding: 0.5em;
                border-radius: calc(2 * var(--border-radius));
            }

            .tile-label {
                font-size: var(--font-size-small);
                color: var(--color-primary);
            }

            .tile-value {
                font-size: var(--font-size-large);
            }

            .tile-value sup {
                font-size: var(--font-size-tiny);
                opacity: 0.5;
                vertical-align: super;
                margin-right: -0.5em;
            }

            .employee-ledgers .footnote {
                opacity: 0.5;
                font-size: var(--font-size-tiny);
                text-align: center;
            }

            .published-roster-dialog {
                --dialog-width: 900px;
                --dialog-max-width: 100%;
                --dialog-height: 100%;
            }

            .published-roster-close {
                width: 100%;
                max-width: 830px;
                padding-bottom: 0.5em;
            }

            .published-roster-dialog iframe {
                ${mixins.fullbleed()};
                width: 100%;
                height: 100%;
            }

            .roster-button {
                width: 100%;
                box-sizing: border-box;
            }

            ptc-scroller.stretch {
                height: 0;
            }

            .spin {
                animation: spin linear 1s infinite;
            }

            .filter-departments-dialog {
                --dialog-max-width: 350px;
            }

            .filter-departments-dialog form {
                padding: 1em;
            }

            .filter-departments-dialog h1 {
                text-align: center;
                margin: 0.1em;
                margin-bottom: 0.7em;
            }

            .filter-departments-dialog .department-item {
                margin: 0.5em 0;
                border-radius: calc(2 * var(--border-radius));
                border: solid 1px;
                color: var(--color-highlight);
            }

            .filtered-departments {
                border-radius: var(--border-radius);
                margin-top: 0.3em;
                padding: 0.6em 0.4em 0 0.6em;
            }

            ptc-qr-code {
                height: 7em;
                width: 7em;
            }

            @keyframes spin {
                to {
                    transform: rotate(360deg);
                }
            }

            @keyframes rumble {
                25% {
                    transform: translate(5px, 0);
                }
                50% {
                    transform: translate(-5px, -3px);
                }
                75% {
                    transform: translate(5px, 2px);
                }
            }

            @media (max-width: 900px) {
                .selection.wrapper {
                    grid-template: "pinpad planned" 1fr "pinpad checkedin" 1fr / 1fr 1fr;
                }
            }

            @media (max-width: 700px) {
                .selection.wrapper {
                    grid-template: "pinpad planned" 1fr "pinpad checkedin" 1fr / 1fr 0;
                    grid-gap: 0;
                }

                .section {
                    display: none;
                }
            }

            @media (max-height: 770px) {
                .pinpad {
                    font-size: var(--font-size-small);
                }
            }

            @media (max-height: 710px) {
                .pinpad {
                    font-size: 0.7rem;
                }
            }

            @media (max-height: 630px) {
                .pinpad {
                    font-size: 0.65rem;
                }
            }

            @media (max-height: 560px) {
                .pinpad {
                    font-size: 0.6rem;
                }
            }
        `,
    ];

    private _renderPinpad() {
        const now = new Date();
        const showQRCode = app.company?.settings.availableTimeSettings.some((s) => s.trackingViaAppEnabled);
        return html`
            <div class="pinpad-wrapper">
                <div class="pinpad">
                    <div class="vertical center-aligning center-justifying layout clock">
                        <select
                            id="venueSelector"
                            class="venue"
                            ?disabled=${this._venues.length === 1}
                            @change=${this._venueSelected}
                        >
                            ${this._venues.map(
                                (venue) => html`
                                    <option
                                        ?selected=${this._venue && venue.id === this._venue.id}
                                        .value=${venue.id.toString()}
                                    >
                                        ${venue.name}
                                    </option>
                                `
                            )}
                        </select>
                        <div class="time">${toTimeString(now)}</div>
                        <div class="date">${formatDate(now)}</div>
                        ${this._longTimeWithoutSync
                            ? html`<div class="tiny semibold red margined padded box">
                                  <i class="exclamation-triangle"></i> <strong>Achtung:</strong> Stempeluhr ist offline.
                                  Bitte prüfen Sie Ihre Internetverbindung!
                              </div>`
                            : html` <div
                                  class="tiny pills click filtered-departments"
                                  @click=${() => this._filterDepartmentsDialog.show()}
                              >
                                  ${this._filterDepartments
                                      ? this._filterDepartments.map((id) => {
                                            const { department } = app.getDepartment(id);
                                            return (
                                                department &&
                                                html` <div class="pill ${department.color}">${department.name}</div> `
                                            );
                                        })
                                      : html` <div class="pill">Alle Abteilungen</div> `}
                              </div>`}
                    </div>

                    ${showQRCode && !this._pin.length
                        ? html`
                              <div class="horizontal centering layout qr-container">
                                  <div class="stretch collapse">
                                      <i class="camera"></i> Jetzt kontaktlos mit der Pentacode Mitarbeiter-App
                                      anmelden!
                                  </div>
                                  <i class="large arrow-right double-margined"></i>
                                  <ptc-qr-code
                                      .value=${this._staffLoginURL || ""}
                                      @click=${() => this._qrDialog.show()}
                                  ></ptc-qr-code>
                              </div>
                          `
                        : html`
                              <div class="pin-display">
                                  <div class="numbers">${this._pin.replace(/\d/g, "•")}</div>
                                  <i class="times click clear-pin" ?hidden=${!this._pin} @click=${this._clearPin}></i>
                                  <div class="pin-error">${this._pinError}</div>
                              </div>
                          `}

                    <button @click=${this._enter} data-digit="1"></button>
                    <button @click=${this._enter} data-digit="2"></button>
                    <button @click=${this._enter} data-digit="3"></button>
                    <button @click=${this._enter} data-digit="4"></button>
                    <button @click=${this._enter} data-digit="5"></button>
                    <button @click=${this._enter} data-digit="6"></button>
                    <button @click=${this._enter} data-digit="7"></button>
                    <button @click=${this._enter} data-digit="8"></button>
                    <button @click=${this._enter} data-digit="9"></button>
                    <button class="special" @click=${this._enter} data-digit="*"></button>
                    <button @click=${this._enter} data-digit="0"></button>
                    <button class="special" @click=${this._enter} data-digit="#"></button>
                </div>
            </div>
        `;
    }

    private _renderPlanned() {
        const timeEntries = this._availableShifts;
        const { trackerDisplayRoster } = app.getTimeSettings({ venue: this._venue });
        return html`
            <div class="section planned">
                <div class="header">Geplant</div>

                <div class="body vertical layout">
                    <ptc-scroller class="stretch">
                        ${timeEntries
                            .filter((a) => !this._isCheckedIn(a))
                            .map((a) => {
                                const employee = app.getEmployee(a.employeeId);
                                if (!employee) {
                                    return html``;
                                }
                                return html`
                                    <div class="time-entry ${this._status(a)}">
                                        <ptc-avatar
                                            .employee=${employee}
                                            .badge=${employee.isBirthDay(this._date)
                                                ? { icon: "birthday-cake" }
                                                : undefined}
                                        ></ptc-avatar>

                                        <div class="middle">
                                            <div class="name">${employee.name}</div>
                                            <div class="tiny pills">
                                                ${employee.status !== EmployeeStatus.Active
                                                    ? html`
                                                          <div
                                                              class="${employeeStatusColor(
                                                                  employee.status
                                                              )} inverted pill"
                                                          >
                                                              ${employeeStatusLabel(employee.status)}
                                                          </div>
                                                      `
                                                    : ""}
                                                ${a.position
                                                    ? html`
                                                          <div
                                                              class="pill"
                                                              style="--color-highlight: ${app.getPositionColor(
                                                                  a.position
                                                              )}"
                                                          >
                                                              ${a.position.name}
                                                          </div>
                                                      `
                                                    : ""}
                                            </div>
                                        </div>

                                        <div class="time">${a.startPlanned ? toTimeString(a.startPlanned) : "?"}</div>
                                    </div>
                                `;
                            })}
                    </ptc-scroller>

                    <div ?hidden=${!trackerDisplayRoster || !this._publishedRosterUrl}>
                        <button class="transparent roster-button" @click=${this._viewRoster}>
                            <i class="calendar"></i>
                            Dienstplan
                        </button>
                    </div>
                </div>
            </div>
        `;
    }

    private _renderActive() {
        const timeEntries = this._availableShifts;
        return html`
            <div class="section checkedin">
                <div class="header">Aktiv</div>

                <div class="body vertical layout">
                    <ptc-scroller class="stretch">
                        ${timeEntries
                            .filter((a) => this._isCheckedIn(a) && !this._isCheckedOut(a))
                            .map((a) => {
                                const employee = app.getEmployee(a.employeeId);
                                if (!employee) {
                                    return html``;
                                }
                                const brkDur =
                                    a.startBreak && Math.ceil((new Date().getTime() - a.startBreak.getTime()) / 60000);
                                return html`
                                    <div class="time-entry">
                                        <ptc-avatar
                                            .employee=${employee}
                                            .badge=${employee.isBirthDay(this._date)
                                                ? { icon: "birthday-cake" }
                                                : undefined}
                                        ></ptc-avatar>

                                        <div class="middle">
                                            <div class="name ellipsis">${employee.name}</div>
                                            <div class="tiny pills">
                                                ${employee.status !== EmployeeStatus.Active
                                                    ? html`
                                                          <div
                                                              class="${employeeStatusColor(
                                                                  employee.status
                                                              )} inverted pill"
                                                          >
                                                              ${employeeStatusLabel(employee.status)}
                                                          </div>
                                                      `
                                                    : ""}
                                                ${a.position
                                                    ? html`
                                                          <div
                                                              class="pill"
                                                              style="--color-highlight: ${app.getPositionColor(
                                                                  a.position
                                                              )}"
                                                          >
                                                              ${a.position.name}
                                                          </div>
                                                      `
                                                    : ""}
                                            </div>
                                        </div>

                                        <div class="orange pill" ?hidden=${!a.startBreak}>
                                            <i class="coffee"></i>
                                            <div class="detail">${brkDur} min</div>
                                        </div>
                                    </div>
                                `;
                            })}
                    </ptc-scroller>
                </div>
            </div>
        `;
    }

    private async _renderEmployeeInfo(emp: Employee) {
        const statement = await app.getMonthlyStatement(emp.id);
        return html`
            <div class="employee-ledgers">
                <div class="tiles">
                    <div class="tile">
                        <div class="tile-label">
                            <i class="clock"></i>
                            Überstunden
                        </div>
                        <div class="tile-value">
                            <ptc-balance .value=${statement.time.reset ?? statement.time.carry}></ptc-balance>
                            <sup>*</sup>
                        </div>
                    </div>

                    <div class="tile">
                        <div class="tile-label">
                            <i class="umbrella-beach"></i>
                            Urlaub
                        </div>
                        <div class="tile-value">
                            <ptc-balance .value=${statement.vacation.reset ?? statement.vacation.carry}></ptc-balance>
                            <sup>*</sup>
                        </div>
                    </div>
                </div>

                <div class="footnote"><sup>*</sup> Stand ${formatDate(statement.from)}</div>
            </div>
        `;
    }

    private _renderRosterNotes(employee: Employee) {
        const today = toDateString(new Date());
        const company = app.company;

        if (!company || !employee) {
            return;
        }

        const notes = this._rosterNotes.filter(
            (note) => matchesFilters(note.filters, { company, employee }) && note.start <= today && note.end > today
        );

        return !notes.length
            ? ""
            : html`
                  <div class="margined">
                      <div class="small text-centering blue colored-text">
                          <i class="sticky-note"></i>
                          Dienstplannotizen
                      </div>
                      ${notes.map(
                          (note) =>
                              html`<ptc-roster-note
                                  .rosterNote=${note}
                                  .employee=${employee}
                                  class="top-margined"
                              ></ptc-roster-note>`
                      )}
                  </div>
              `;
    }

    private _renderEmployeeActions() {
        if (!app.company || !this._venue || !this._selectedEmployee) {
            return;
        }
        const status = this._status();
        const employee = this._selectedEmployee;
        const timeEntry = this._selectedTimeEntry;
        const breakDur = (timeEntry && timeEntry.break) || 0;
        const workDur =
            timeEntry && timeEntry.final
                ? Math.max(0, (timeEntry.end.getTime() - timeEntry.start.getTime()) / hour - breakDur)
                : 0;

        const availablePositions = app.getUnplannedShiftPositions(employee, this._venue?.id, this._filterDepartments);

        const { breakMode } = (timeEntry && app.getTimeSettings({ timeEntry })) || { breakMode: 0 };

        const { trackerDisplayEmployeeInfo } = app.getTimeSettings({ employee, venue: this._venue });

        return html`
            <div class="employee-actions wrapper" ?showing=${!!employee}>
                <button class="huge transparent icon close-button" @click=${this._back}>
                    <i class="times"></i>
                </button>

                <ptc-scroller class="fullbleed">
                    <div class="centering vertical layout fill-vertically">
                        <div class="employee-actions-inner vertical layout">
                            <ptc-avatar
                                .employee=${employee}
                                .editable=${app.hasPermission("manage.employees")}
                                @edit=${this._updateAvatar}
                                .badge=${employee.isBirthDay(this._date) ? { icon: "birthday-cake" } : undefined}
                            ></ptc-avatar>

                            <div class="employee-name ellipsis">${employee && employee.name}</div>

                            ${trackerDisplayEmployeeInfo && employee.status !== EmployeeStatus.Probation
                                ? until(this._renderEmployeeInfo(employee), html` <ptc-spinner active></ptc-spinner> `)
                                : ""}
                            ${timeEntry
                                ? html`
                                      <div class="selected-time-entry card">
                                          <div class="selected-time-entry-label ${status}">
                                              ${status === "complete"
                                                  ? html`<i class="check"></i> Schicht Abgeschlossen</i>`
                                                  : status === "active"
                                                    ? html`<i class="clock"></i> Im Dienst</i>`
                                                    : status === "onbreak"
                                                      ? html` <i class="coffee"></i> In der Pause `
                                                      : html`<i class="calendar"></i> Geplante Schicht</i>`}
                                          </div>

                                          <div class="tiles">
                                              <div class="tile">
                                                  <div class="tile-label">
                                                      <i class="user"></i>
                                                      Position
                                                  </div>
                                                  <div class="tile-value ellipsis">
                                                      ${timeEntry && timeEntry.position && timeEntry.position.name}
                                                  </div>
                                              </div>

                                              <div class="tile">
                                                  <div class="tile-label">
                                                      <i class="calendar"></i>
                                                      Dienstplan
                                                  </div>
                                                  <div class="tile-value">
                                                      ${timeEntry.startPlanned
                                                          ? toTimeString(timeEntry.startPlanned)
                                                          : "?"}
                                                      -
                                                      ${timeEntry.endPlanned ? toTimeString(timeEntry.endPlanned) : "?"}
                                                  </div>
                                              </div>

                                              <div class="tile" ?hidden=${!timeEntry.startFinal && !timeEntry.endFinal}>
                                                  <div class="tile-label">
                                                      <i class="stopwatch"></i>
                                                      Geloggt
                                                  </div>
                                                  <div class="tile-value">
                                                      ${timeEntry.startFinal ? toTimeString(timeEntry.startFinal) : "?"}
                                                      - ${timeEntry.endFinal ? toTimeString(timeEntry.endFinal) : "?"}
                                                  </div>
                                              </div>

                                              ${timeEntry.comment
                                                  ? html`
                                                        <div
                                                            class="tile centering spacing vertical layout"
                                                            style="grid-column: 1/-1;"
                                                        >
                                                            <div class="tile-label text-centering">
                                                                <i class="comment"></i>
                                                                Bemerkungen
                                                            </div>
                                                            <div class="tile-value">
                                                                <pre class="default">${timeEntry.comment}</pre>
                                                            </div>
                                                        </div>
                                                    `
                                                  : ""}
                                          </div>

                                          <div class="tiles" ?hidden=${status !== "complete"}>
                                              <div class="tile">
                                                  <div class="tile-label">
                                                      <i class="clock"></i>
                                                      Arbeitszeit
                                                  </div>
                                                  <div class="tile-value">
                                                      <strong>${Math.floor(workDur)}</strong> Std
                                                      <strong>${((workDur % 1) * 60).toFixed(0)}</strong> Min
                                                  </div>
                                              </div>

                                              <div class="tile">
                                                  <div class="tile-label">
                                                      <i class="coffee"></i>
                                                      Pause
                                                  </div>
                                                  <div class="tile-value">
                                                      <strong>${(breakDur * 60).toFixed(0)}</strong> Min
                                                  </div>
                                              </div>
                                          </div>

                                          <div class="shift-actions tiles" ?hidden=${status === "complete"}>
                                              <button
                                                  class="primary"
                                                  ?hidden=${this._isCheckedIn()}
                                                  @click=${() => this._checkIn()}
                                              >
                                                  <i class="play-circle"></i>
                                                  Schicht Beginnen
                                              </button>

                                              <button
                                                  class="orange"
                                                  ?hidden=${[BreakMode.Auto, BreakMode.Planned].includes(breakMode) ||
                                                  !this._isCheckedIn() ||
                                                  this._isOnBreak() ||
                                                  this._isCheckedOut()}
                                                  @click=${() => this._startBreak()}
                                              >
                                                  <i class="coffee"></i>
                                                  Pause
                                              </button>

                                              <button
                                                  class="primary"
                                                  ?hidden=${!this._isCheckedIn() ||
                                                  this._isOnBreak() ||
                                                  this._isCheckedOut()}
                                                  @click=${() => this._checkOut()}
                                              >
                                                  <i class="stop-circle"></i>
                                                  Schicht Beenden
                                              </button>

                                              <button
                                                  class="orange"
                                                  ?hidden=${!this._isCheckedIn() ||
                                                  !this._isOnBreak() ||
                                                  this._isCheckedOut()}
                                                  @click=${() => this._endBreak()}
                                              >
                                                  <i class="coffee"></i>
                                                  Pause Beenden
                                              </button>
                                          </div>
                                      </div>
                                  `
                                : html`
                                      <div class="selected-time-entry card">
                                          <div class="not-planned">Keine geplante Schicht gefunden!</div>

                                          <div class="tiles shift-actions" ?hidden=${!availablePositions.length}>
                                              <button class="primary" @click=${() => this._selectPosition()}>
                                                  <i class="play-circle"></i>
                                                  Spontane Schicht
                                              </button>
                                          </div>
                                      </div>
                                  `}
                            ${this._renderRosterNotes(employee)}

                            <button
                                class="large ${status === "complete" ? "positive" : "transparent"}"
                                @click=${this._back}
                            >
                                Schließen
                            </button>
                        </div>
                    </div>
                </ptc-scroller>
            </div>

            <ptc-dialog id="selectPositionDialog" class="select-position-dialog">
                <div class="inner">
                    <h1>Schicht Beginnen</h1>

                    <h3>Wählen Sie einen Arbeitsbereich!</h3>

                    ${availablePositions.map(
                        ({ department, position }) => html`
                            <button
                                class="large highlight ${department.color}"
                                @click=${() => this._createTimeEntry(position)}
                            >
                                ${department.name} / ${position.name}
                            </button>
                        `
                    )}

                    <button class="large transparent" @click=${() => this._selectPositionDialog.dismiss()}>
                        Abbrechen
                    </button>
                </div>
            </ptc-dialog>
        `;
    }

    private _renderLogin() {
        return html`
            <div class="login" ?hidden=${!app.hasLoaded || !!app.account}>
                <div class="container vertical center-aligning center-justifying layout">
                    <img src="${Logo}" class="logo" />

                    <h1>Zeiterfassung</h1>

                    <div class="greeting">Willkommen zur Pentacode Zeiterfassung! Bitte melden Sie sich an.</div>

                    <div class="card">
                        <form class="vertical layout" @submit=${this._login} ?disabled=${this._loading}>
                            <div class="field">
                                <input type="email" id="email" placeholder="E-mail Adresse" />
                            </div>

                            <div class="field">
                                <input type="password" id="password" placeholder="Passwort" />
                            </div>

                            <div class="negative message" ?hidden=${!this._loginError}>${this._loginError}</div>

                            <button type="submit" class="primary">Anmelden</button>
                        </form>
                    </div>
                </div>
            </div>
        `;
    }

    render() {
        return html`
            <div class="selection wrapper" ?showing=${!this._selectedEmployee}>
                ${this._renderPinpad()} ${this._renderPlanned()} ${this._renderActive()}
            </div>

            ${this._renderEmployeeActions()} ${this._renderLogin()}

            <div class="footer center-aligning center-justifying horizontal layout" @click=${() => this._poll(true)}>
                <div>Pentatime <strong>v${version}</strong></div>
                <i class="sync ${this._synching ? "spin" : ""}"></i>
                <div class="last-sync ${this._longTimeWithoutSync ? "old" : ""}">
                    ${formatDate(this._lastSync)} ${this._lastSync.toLocaleTimeString()}
                </div>
                <div class="connection-status bold ${this.offline ? "offline" : "online"}">
                    <i class="circle"></i>
                    ${this.offline ? "offline" : ""}
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>

            <slot></slot>

            <ptc-dialog class="published-roster-dialog">
                <div class="horizontal end-justifying layout published-roster-close" slot="before">
                    <button class="transparent padded-light" @click=${() => this._publishedRosterDialog.dismiss()}>
                        <i class="times"></i>
                        Schließen
                    </button>
                </div>
                <iframe .src=${this._publishedRosterUrl}></iframe>
            </ptc-dialog>

            <ptc-dialog id="filterDepartmentsDialog" class="filter-departments-dialog">
                <ptc-scroller class="fit-vertically">
                    <form id="filterDepartmentsForm" @submit=${this._submitFilterDepartments}>
                        <h1>Abteilungen</h1>

                        ${this._venue &&
                        this._venue.departments
                            .filter((d) => app.getTimeSettings(d).trackingEnabled)
                            .map(
                                (dep) => html`
                                    <ptc-checkbox-button
                                        .label=${`${dep.name}`}
                                        buttonClass="transparent"
                                        name="departments"
                                        .value=${dep.id.toString()}
                                        class="department-item"
                                        style="--color-highlight: ${colors[dep.color] || dep.color}"
                                        .checked=${!this._filterDepartments || this._filterDepartments.includes(dep.id)}
                                    ></ptc-checkbox-button>
                                `
                            )}

                        <div class="actions grid">
                            <button class="primary">Speichern</button>
                            <button
                                type="button"
                                class="transparent"
                                @click=${() => this._filterDepartmentsDialog.dismiss()}
                            >
                                Abbrechen
                            </button>
                        </div>
                    </form>
                </ptc-scroller>
            </ptc-dialog>

            <ptc-dialog id="qrDialog" style="--dialog-width: auto">
                <div class="padded">
                    <div class="horizontal start-aligning layout bottom-margined">
                        <div class="stretch collapse margined">
                            <i class="camera"></i> Jetzt kontaktlos mit der Pentacode Mitarbeiter-App anmelden!
                        </div>
                        <button class="transparent" @click=${() => this._qrDialog.dismiss()}>
                            <i class="times"></i>
                        </button>
                    </div>
                    <ptc-qr-code class="enormous" .scale=${16} .value=${this._staffLoginURL || ""}></ptc-qr-code>
                </div>
            </ptc-dialog>
        `;
    }
}
